html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

td:not([align]),
th:not([align]) {
  text-align: left;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #111111;
  color: #ffffff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

iframe {
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 0 2rem;
  height: 3.25rem;
  border-radius: 6px;
  margin: 0;
  text-decoration: none;
  background: #e60c31;
  color: #ffffff;
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button:hover,
.button:focus {
  background: #e60c31;
}

.button:focus {
  outline: none;
}

.button:active {
  transform: scale(0.98);
}

.page {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.5);
}

.page-not-found span:first-child {
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 2px solid currentColor;
}

.page-not-found span:last-child {
  margin-left: 1rem;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  background-color: #000000;
  height: 4px;
  position: relative;
  width: 320px;
  overflow: hidden;
  border-radius: 6px;
}

.progress-bar {
  animation-duration: 1s;
  animation-name: progress;
  animation-iteration-count: infinite;
  background-color: #e60c31;
  height: 100%;
  width: 100%;
  position: relative;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
